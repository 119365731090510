export const INITIALIZE_THE_LOADER = "INITIALIZE_THE_LOADER";
export const STOP_THE_LOADER = "STOP_THE_LOADER";
export const INITIALIZE_THE_PAGE_LOADER = "INITIALIZE_THE_PAGE_LOADER";
export const STOP_THE_PAGE_LOADER = "STOP_THE_PAGE_LOADER";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_USER = "GET_USER";
export const GET_USER_DROPDOWN = "GET_USER_DROPDOWN";
export const ADD_ROLE = "ADD_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const GET_ROLE = "GET_ROLE";
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_ROLE_PERMISSION = "GET_ROLE_PERMISSION";
export const ADD_BASIC_DETAILS = "ADD_BASIC_DETAILS";
export const ADD_BANK_DETAILS = "ADD_BANK_DETAILS";
export const ADD_CREDIT_DETAILS = "ADD_CREDIT_DETAILS";
export const GET_CREDIT_DETAILS = "GET_CREDIT_DETAILS";
export const GET_WALLET_AMOUNT = "GET_WALLET_AMOUNT";
export const GET_WALLET_COIN = "GET_WALLET_COIN";
export const ADD_KYC_DETAILS = "ADD_KYC_DETAILS";
export const GET_DEALER_LIST = "GET_DEALER_LIST";
export const UPDATE_BASIC_DETAILS = "UPDATE_BASIC_DETAILS";
export const RESET_DEALER = "RESET_DEALER";
export const ADD_SERVICE_HISTORY = "ADD_SERVICE_HISTORY";
export const EDIT_SERVICE_HISTORY = "EDIT_SERVICE_HISTORY";
export const GET_SERVICE_HISTORY = "GET_SERVICE_HISTORY";
export const ADD_PICKUP_REQUEST = "ADD_PICKUP_REQUEST";
export const EDIT_PICKUP_REQUEST = "EDIT_PICKUP_REQUEST";
export const GET_PICKUP_REQUEST = "GET_PICKUP_REQUEST";
export const ADD_RTO_CASES_REQUEST = "ADD_RTO_CASES_REQUEST";
export const EDIT_RTO_CASES_REQUEST = "EDIT_RTO_CASES_REQUEST";
export const GET_RTO_CASES_REQUEST = "GET_RTO_CASES_REQUEST";
export const ADD_CHALLAN_REQUEST = "ADD_CHALLAN_REQUEST";
export const EDIT_CHALLAN_REQUEST = "EDIT_CHALLAN_REQUEST";
export const GET_CHALLAN_REQUEST = "GET_CHALLAN_REQUEST";
export const CREATE_CHALLAN_PAY_REQUEST = "CREATE_CHALLAN_PAY_REQUEST";
export const GET_CAR_CHALLAN_LIST = "GET_CAR_CHALLAN_LIST";
export const GET_CAR_PENDING_CHALLAN_LIST = "GET_CAR_PENDING_CHALLAN_LIST";
export const ADD_PENDING_CHALLANS = "ADD_PENDING_CHALLANS";
export const EDIT_CHALLAN = "EDIT_CHALLAN";
export const ADD_BANK_TRANSACTION = "ADD_BANK_TRANSACTION";
export const GET_BANK_TRANSACTION = "GET_BANK_TRANSACTION";
export const GET_OUTSTANDING_BANK_TRANSACTION =
  "GET_OUTSTANDING_BANK_TRANSACTION";
export const CREATE_CAR_DETAIL = "CREATE_CAR_DETAIL";
export const EDIT_CAR_DETAIL = "EDIT_CAR_DETAIL";
export const GET_CAR_LIST = "GET_CAR_LIST";
export const ADD_PHOTOSHOOT_REQUEST = "ADD_PHOTOSHOOT_REQUEST";
export const EDIT_PHOTOSHOOT_REQUEST = "EDIT_PHOTOSHOOT_REQUEST";
export const GET_PHOTOSHOOT_REQUEST = "GET_PHOTOSHOOT_REQUEST";
export const ADD_SOCIALMEDIA_REQUEST = "ADD_SOCIALMEDIA_REQUEST";
export const EDIT_SOCIALMEDIA_REQUEST = "EDIT_SOCIALMEDIA_REQUEST";
export const GET_SOCIALMEDIA_REQUEST = "GET_SOCIALMEDIA_REQUEST";
export const ADD_DM_REQUEST = "ADD_DM_REQUEST";
export const EDIT_DM_REQUEST = "EDIT_DM_REQUEST";
export const GET_DM_REQUEST = "GET_DM_REQUEST";
export const ADD_WEBSITEDEVELOPMENT_REQUEST = "ADD_WEBSITEDEVELOPMENT_REQUEST";
export const EDIT_WEBSITEDEVELOPMENT_REQUEST =
  "EDIT_WEBSITEDEVELOPMENT_REQUEST";
export const GET_WEBSITEDEVELOPMENT_REQUEST = "GET_WEBSITEDEVELOPMENT_REQUEST";
