import {
  ADD_BANK_DETAILS,
  ADD_BASIC_DETAILS,
  ADD_CREDIT_DETAILS,
  ADD_KYC_DETAILS,
  GET_CREDIT_DETAILS,
  GET_DEALER_LIST,
  GET_WALLET_AMOUNT,
  GET_WALLET_COIN,
  RESET_DEALER,
  UPDATE_BASIC_DETAILS,
} from "../types";

const initialState = {
  dealerData: {
    basicDetails: {},
    kycDetails: {},
    financeData: [],
    walletAmount: 0,
    coinAmount: 0,
    bankDetails: {},
  },

  dealerList: [],
  total: 0,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_BASIC_DETAILS:
      return {
        ...state,
        dealerData: { ...state?.dealerData, basicDetails: payload },
      };

    case ADD_BANK_DETAILS:
      return {
        ...state,
        dealerData: { ...state?.dealerData, bankDetails: payload },
      };

    case ADD_CREDIT_DETAILS:
      return {
        ...state,
        dealerData: { ...state?.dealerData, ...payload },
      };

    case GET_CREDIT_DETAILS:
      return {
        ...state,
        dealerData: { ...state?.dealerData, financeData: payload },
      };

    case GET_WALLET_AMOUNT:
      return {
        ...state,
        dealerData: { ...state?.dealerData, walletAmount: payload },
      };

    case GET_WALLET_COIN:
      return {
        ...state,
        dealerData: { ...state?.dealerData, coinAmount: payload },
      };

    case ADD_KYC_DETAILS:
      return {
        ...state,
        dealerData: { ...state?.dealerData, kycDetails: payload },
      };
    case GET_DEALER_LIST:
      return {
        ...state,
        dealerList: payload?.data,
        total: payload?.total,
      };

    case UPDATE_BASIC_DETAILS:
      return {
        ...state,
        dealerData: { ...state?.dealerData, basicDetails: payload },
      };

    case RESET_DEALER:
      return {
        ...state,
        dealerData: {
          basicDetails: {},
          kycDetails: {},
          financeData: [],
          walletAmount: {},
          coinAmount: {},
          bankDetails: {},
        },
      };
    default:
      return state;
  }
}
