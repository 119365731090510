import axios from "axios";
import axiosInstance from "axios";

import {
  API_URL,
  DEALER_URL,
  SERVICE_HISTORY_URL,
  INVENTORY_URLS,
} from "../config";

//const accessToken = localStorage.getItem('accessToken');

export const call = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axios({
    method,
    url: API_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const customerCall = async (method, url, params = {}, data = {}) => {
  console.log("data ", data);
  const result = await axios({
    method,
    url: "http://localhost:8000/api/v1" + url,
    params,
    data,
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};

export const uploadedDocumentDealerApi = async (
  method,
  url,
  params = {},
  data = {}
) => {
  console.log("data ", data);
  const result = await axios({
    method,
    url: DEALER_URL + `admin-dealer-auth${url}`,
    params,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};

export const UpdateDealerUploadDocument = async (
  method,
  url,
  params = {},
  data = {}
) => {
  console.log("data ", data);
  const result = await axios({
    method,
    url: DEALER_URL + `admin-dealer-auth${url}`,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};

export const uploadcall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const brancall = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      // authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const dealerCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axios({
    method,
    url: DEALER_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const serviceHistoryCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axios({
    method,
    url: SERVICE_HISTORY_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const inventoryCreate = async (
  method,
  url,
  params = {},
  data,
  token
) => {

  try {
    const result = await axiosInstance({
      method,
      url: INVENTORY_URLS + url,
      params,
      data,
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    });
    return result.data;
  } catch (error) {
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    console.error("Error config:", error.config);

    throw error;
  }
};

export const carList = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axios({
    method,
    url: INVENTORY_URLS + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const templateCall = async (method, url) => {
  const result = await axios({
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  return result;
};


export const uplodTemplate = async (method, url, formData) => {
  const result = await axios({
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
    responseType: "json",
  });
  return result;
};

export const templateDetails = async (method, url, params = {}) => {
  const result = await axios({
    method,
    url: DEALER_URL + 'admin-dealer-auth/' + url,
    params,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  return result;
};
