import style from "./style.module.scss";
import { R } from "../../constants/resources";
import { useLocation } from "react-router-dom";
import { getHeadings } from "../../constants/headings";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/auth/authAction";

export const Header = ({ onNavClick }) => {
  const location = useLocation();
  const headings = getHeadings(location?.pathname);
  const { admin } = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  const hanldeLogout = () => {
    dispatch(logout({ id: admin?.data?.id }));
  };


  return (
    <div className={style["container"]}>
      <div className={style["wrapper"]}>
        <h2 className={style["section-header"]}>{headings[0]}</h2>
        <div className={style["screen-heading"]}>
          {headings?.map((screenTitle, index) => {
            if (index > 0) return <p key={index}>{screenTitle}</p>;
            else return <></>;
          })}
        </div>
        <img
          onClick={onNavClick}
          className={style["menu-hamburger_mobile"]}
          src={R.ic_hamburger}
          alt={"Menu hamburger icon"}
        />
        <div className={style["search-bar"]}>
          {/* <img src={R.ic_search} alt="" />
          <input type="text" placeholder="Search" /> */}
        </div>
        <img
          className={style["action-button"]}
          src={R.ic_notification}
          alt=""
        />
        <img
          className={style["action-button"]}
          src={R.ic_logout}
          alt=""
          onClick={hanldeLogout}
        />
        <div
          style={{ backgroundImage: `url(${R.ic_profile})` }}
          className={style["profile-pic"]}
        ></div>
      </div>
      <div className={style["heading-container_mobile"]}>
        <h2 className={style["section-heading_mobile"]}>{headings[0]}</h2>
        {headings?.map((screenTitle, index) => {
          if (index > 0) return <p key={index}>{screenTitle}</p>;
          else return <></>;
        })}
      </div>
    </div>
  );
};
